import { useSelector, useDispatch } from 'react-redux';
import { createAction, handleActions } from 'redux-actions';
import {
  getTotalDocsCreateDate as getTotalDocsCreateDateApi,
  getDocById as getDocByIdApi,
  getDocs as getDocsApi,
  getDocsByStatus as getDocsByStatusApi,
  getDocsByName as getDocsByNameApi,
  getDocBooks as getDocBooksApi,
  deleteDocBook as deleteDocBookApi,
  createInteractiveObject as createInteractiveObjectApi,
  docConvert as docConvertApi,
  docConvertToPng as docConvertToPngApi,
  updateDocBook as updateDocBookApi,
  uploadPdfFile as uploadPdfFileApi,
  uploadCSVFile as uploadCSVFileApi
} from 'services/api/doc';
import { OPTIONS_TYPE, STATUS_TYPE } from 'constants/index';
import { convertArrayToMap } from 'utils/array';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';

const GET_TOTAL_DOCS_CREATE_DATE = 'GET_TOTAL_DOCS_CREATE_DATE';
const GET_DOCS = 'GET_DOCS';
const DOC_DELETE_BOOK = 'DOC_DELETE_BOOK';
const CREATE_INTERACTIVE_OBJECT = 'CREATE_INTERACTIVE_OBJECT';
const DOC_CONVERT = 'DOC_CONVERT';
const DOC_CONVERT_TO_PNG = 'DOC_CONVERT_TO_PNG';
const UPDATE_BOOK_JSON_CONTENT = 'UPDATE_BOOK_JSON_CONTENT';
const UPLOAD_PDF = 'UPLOAD_PDF';

const initState = {
  totalDocsCreateDate: {
    isLoading: false,
    data: [],
    total: 0
  },
  docs: {
    total: 0,
    data: [],
    dataMap: {},
    isLoading: false
  },
  searchType: '',
  upload: {
    isLoading: false
  }
};

export const useDoc = () => {
  const dispatch = useDispatch();
  const { setAlert } = useAlert();

  const { totalDocsCreateDate, docs, searchType, upload } = useSelector(state => state.doc);

  const getTotalDocsCreateDateAction = createAction(GET_TOTAL_DOCS_CREATE_DATE, async () => {
    try {
      const { data, isSuccess, error } = await getTotalDocsCreateDateApi();
      if (!isSuccess) throw error;
      return {
        data,
        total: data.length
      };
    } catch (error) {
      return {
        data: [],
        total: 0
      };
    }
  });

  const getTotalDocsCreateDate = () => dispatch(getTotalDocsCreateDateAction());

  const getDocsAction = createAction(
    GET_DOCS,
    async ({ bookId, page, status, name }) => {
      let data = [];
      let total  = 0;
      let searchType = '';

      try {

        if (bookId) {
          const { data: responseData, isSuccess, error } = await getDocByIdApi(bookId);
          if (!isSuccess) throw error;
          data = [responseData];
          searchType = OPTIONS_TYPE.BookId;
        }

        if (status) {
          const { data: responseData, isSuccess, error } = await getDocsByStatusApi(status);
          if (!isSuccess) throw error;
          data = responseData;
          searchType = OPTIONS_TYPE.Status;
        }

        if (name) {
          const { data: responseData, isSuccess, error } = await getDocsByNameApi({ name, page });
          if (!isSuccess) throw error;
          data = responseData.books;
          total = responseData.total;
          searchType = OPTIONS_TYPE.Name;
        }

        if (page && !status && !name && !bookId) {
          const index = Math.max((page - 1) * 10, 0);
          const { data: responseData, isSuccess, error } = await getDocsApi({
            createDateBefore: totalDocsCreateDate.data[index]
          });
          if (!isSuccess) throw error;
          data = responseData;
          searchType = '';
        }

        const bookIds = data.map(item => item.bookId);
        const { content, isSuccess: isGetDocBookSuccess, error: getDocBookError } = await getDocBooksApi(bookIds);
        if (!isGetDocBookSuccess) throw getDocBookError;

        const bookIdObjectMap = content.reduce((acc, item) => {
          acc[item.bookId] = {
            interactiveObjectId: item.interactiveObjectId || '',
            productItemId: item.id || ''
          };
          return acc;
        }, {});

        const result = data.map(item => {
          const { interactiveObjectId, productItemId } = bookIdObjectMap[item.bookId] || {};
          return {
            ...item,
            interactiveObjectId,
            productItemId,
            isDeleted: false
          };
        });

        return {
          total,
          data: result,
          searchType
        };

      } catch (error) {

        return {
          total,
          data: [],
          searchType
        };

      }

    });

  const getDocs = params => dispatch(getDocsAction(params));

  const deleteDocBookAction = createAction(DOC_DELETE_BOOK, async bookId => {
    try {
      const { isSuccess, error } = await deleteDocBookApi(bookId);
      if (!isSuccess) throw error;
      setAlert('刪除書本成功', 'success');
      return {
        bookId
      };
    } catch (error) {
      console.error(error);
      setAlert('刪除書本失敗', 'error');
    }
  });

  const deleteBook = bookId => dispatch(deleteDocBookAction(bookId));

  const createInteractiveObjectAction = createAction(CREATE_INTERACTIVE_OBJECT, async bookId => {
    try {
      const { content, isSuccess, error } = await createInteractiveObjectApi(bookId);
      if (!isSuccess) throw error;
      setAlert('創立編輯紀錄成功', 'success');
      return {
        data: content
      };
    } catch (error) {
      console.error(error);
      setAlert('創立編輯紀錄失敗', 'error');
    }
  });

  const createInteractiveObject = bookId => dispatch(createInteractiveObjectAction(bookId));

  const docConvertAction = createAction(DOC_CONVERT, async bookId => {
    try {
      const { isSuccess, error } = await docConvertApi(bookId);
      if (!isSuccess) throw error;
      setAlert('轉檔開始', 'success');
      return {
        bookId
      };
    } catch (error) {
      console.error(error);
      setAlert('轉檔失敗', 'error');
    }
  });

  const docConvert = bookId => dispatch(docConvertAction(bookId));

  const docConvertToPngAction = createAction(DOC_CONVERT_TO_PNG, async bookId => {
    try {
      const { isSuccess, error } = await docConvertToPngApi(bookId);
      if (!isSuccess) throw error;
      setAlert('轉檔開始', 'success');
      return {
        bookId
      };
    } catch (error) {
      console.error(error);
      setAlert('轉檔失敗', 'error');
    }
  });

  const docConvertToPng = bookId => dispatch(docConvertToPngAction(bookId));

  const updateBookJsonContentAction = createAction(UPDATE_BOOK_JSON_CONTENT, async ({ bookId, params }) => {
    try {
      const { isSuccess, error } = await updateDocBookApi({ bookId, params });
      if (!isSuccess) throw error;
      setAlert('更新內容成功', 'success');
      return {
        bookId,
        params
      };
    } catch (error) {
      console.error(error);
      setAlert('更新內容失敗', 'error');
    }
  });

  const updateBookJsonContent = (bookId, params) => dispatch(updateBookJsonContentAction({ bookId, params }));

  const uploadPDFAction = createAction(UPLOAD_PDF, async (uploadParams, formData) => {
    try {
      const { status, content } = await uploadPdfFileApi(uploadParams, formData);
      if (status !== 'success') throw new Error({
        errorMessage: 'upLoadFile Fail'
      });
      setAlert(ALERT_MESSAGE.UPLOAD_SUCCESS, 'success');
      return content;
    } catch (error) {
      setAlert(ALERT_MESSAGE.UPLOAD_FAIL, 'fail');
    }
  });

  const uploadPDF = (uploadParams, formData) => dispatch(uploadPDFAction(uploadParams, formData));

  const uploadCSVAction =  createAction(UPLOAD_PDF, async (formData) => {
    try {
      const { status, content } = await uploadCSVFileApi(formData);
      if (status !== 'success') throw new Error({
        errorMessage: 'upLoadFile Fail'
      });
      setAlert(ALERT_MESSAGE.UPLOAD_SUCCESS, 'success');
      return content;
    } catch (error) {
      setAlert(ALERT_MESSAGE.UPLOAD_FAIL, 'fail');
    }
  });

  const uploadCSV = (formData) => dispatch(uploadCSVAction(formData));

  return [
    { totalDocsCreateDate, docs, searchType, upload }, // state
    {
      getTotalDocsCreateDate,
      getDocs,
      deleteBook,
      createInteractiveObject,
      docConvert,
      docConvertToPng,
      updateBookJsonContent,
      uploadPDF,
      uploadCSV
    }, // eventHanlder
  ];
};

const updateDocs = (bookId, docs) => params => {
  const docsMap = convertArrayToMap(docs, 'bookId') || {};
  docsMap[bookId] = {
    ...docsMap[bookId],
    ...params
  };
  return {
    data: Object.values(docsMap),
    dataMap: docsMap
  };
};

const reducer = handleActions(
  {
    GET_TOTAL_DOCS_CREATE_DATE_PENDING: (state) => ({
      ...state,
      totalDocsCreateDate: {
        ...state.totalDocsCreateDate,
        isLoading: true,
      }
    }),
    GET_TOTAL_DOCS_CREATE_DATE_FULFILLED: (state, action) => ({
      ...state,
      totalDocsCreateDate: {
        data: action.payload.data,
        total: action.payload.total,
        isLoading: false,
      }
    }),
    GET_DOCS_PENDING: state => ({
      ...state,
      docs: {
        ...state.docs,
        isLoading: true
      }
    }),
    GET_DOCS_FULFILLED: (state, action) => ({
      ...state,
      docs: {
        data: action.payload.data,
        dataMap: convertArrayToMap(action.payload.data, 'bookId'),
        isLoading: false,
        total: action.payload.total
      },
      searchType: action.payload.searchType
    }),
    GET_DOCS_REJECTED: state => ({
      ...state,
      docs: {
        ...state.docs,
        isLoading: false,
        total: 0
      }
    }),
    CREATE_INTERACTIVE_OBJECT_FULFILLED: (state, action) => {
      const { bookId, interactiveObjectId, id: productItemId } = action.payload.data;
      const { data, dataMap } = updateDocs(bookId, state.docs.data)({
        interactiveObjectId,
        productItemId
      });
      return {
        ...state,
        docs: {
          ...state.docs,
          data,
          dataMap
        }
      };
    },
    DOC_DELETE_BOOK_FULFILLED: (state, action) => {
      const { bookId } = action.payload;
      const { data, dataMap } = updateDocs(bookId, state.docs.data)({ 'isDeleted': true });
      return {
        ...state,
        docs: {
          ...state.docs,
          data,
          dataMap
        }
      };
    },
    DOC_CONVERT_FULFILLED: (state, action) => {
      const { bookId } = action.payload;
      const { data, dataMap } = updateDocs(bookId, state.docs.data)({ 'status': STATUS_TYPE.Waiting });
      return {
        ...state,
        docs: {
          ...state.docs,
          data,
          dataMap
        }
      };
    },
    DOC_CONVERT_TO_PNG_FULFILLED: (state, action) => {
      const { bookId } = action.payload;
      const { data, dataMap } = updateDocs(bookId, state.docs.data)({ 'status': STATUS_TYPE.Waiting });
      return {
        ...state,
        docs: {
          ...state.docs,
          data,
          dataMap
        }
      };
    },
    UPDATE_BOOK_JSON_CONTENT: (state, action) => {
      return {
        ...state,
        docs: {
          ...state.docs,
          isLoading: true
        }
      };
    },
    UPDATE_BOOK_JSON_CONTENT_FULFILLED: (state, action) => {
      const { bookId, params } = action.payload;
      const { data, dataMap } = updateDocs(bookId, state.docs.data)(params);
      return {
        ...state,
        docs: {
          ...state.docs,
          data,
          dataMap,
          isLoading: false
        }
      };
    },
    UPLOAD_PDF_PENDING: state => ({
      ...state,
      upload: {
        isLoading: true
      }
    }),
    UPLOAD_PDF_FULFILLED: (state, action) => ({
      ...state,
      upload: {
        isLoading: false
      }
    }),
    UPLOAD_PDF_REJECTED: state => ({
      ...state,
      upload: {
        isLoading: false
      }
    }),
  },
  initState
);

export default reducer;
